import './cellRenderers.css';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { formatDate, formatDateTime, formatTime } from '@icp/i18n';
import { getCascadedTimeZone } from '@icp/settings';

function toDateObj(value, cellRendererParams) {
  const { dateValueType } = cellRendererParams || {};
  if (dateValueType === 'timestamp') {
    return dayjs(+value).toDate();
  }
  if (dateValueType === 'unix_timestamp') {
    return dayjs.unix(+value).toDate();
  }
  if (dateValueType === 'time') {
    return dayjs(value, 'HH:mm:ss').toDate();
  }
  return dayjs(value).toDate();
}

function formatDateObj(date, cellRendererParams) {
  const { formatterType, dateFormat, formatOptions } = cellRendererParams || {};

  if (!date) {
    return '';
  }

  if (dateFormat) {
    return dayjs(date).format(dateFormat);
  }
  if (formatterType === 'datetime') {
    const timeZone = formatOptions?.timeZone || getCascadedTimeZone();
    return formatDateTime(date, { timeZone, ...formatOptions });
  }
  if (formatterType === 'time') {
    return formatTime(date, formatOptions);
  }
  return formatDate(date, formatOptions);
}

function DateCellRenderer(props) {
  const { value, valueFormatted, dateValueType, formatterType, dateFormat, formatOptions } = props;

  if (!value) return null;

  // '[object Object]' 是因为 ag-grid 在 valueFormatted 做了多余的事情，最后判断如果 value 是 array 会强制调用 value.join(', '),
  // 导致即使 colDef 没有提供 valueFormatter 的时候 [{}, {}] 会变成 '[object Object], [object Object]'
  const displayText =
    typeof valueFormatted === 'number' ||
    (typeof valueFormatted === 'string' && !valueFormatted.startsWith('[object Object]'))
      ? valueFormatted
      : DateCellRenderer.toDisplayText(value, {
          dateValueType,
          formatterType,
          dateFormat,
          formatOptions,
        });

  return <span className="table-date-cell">{displayText}</span>;
}

DateCellRenderer.toDisplayText = (value, cellRendererParams) => {
  if (!value) return value;
  const date = toDateObj(value, cellRendererParams);
  return formatDateObj(date, cellRendererParams);
};

DateCellRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateValueType: PropTypes.oneOf(['timestamp', 'unix_timestamp', 'ISO', 'time']),
  formatterType: PropTypes.oneOf(['date', 'time', 'datetime']),
  dateFormat: PropTypes.string,
  /**
   * Intl.DateTimeFormat的所有属性
   */
  formatOptions: PropTypes.shape({}),
};

export default DateCellRenderer;
