/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Popover } from '@icp/components';
import { resolveNestedValue } from '@icp/form-renderer-core';
import dayjs from 'dayjs';
import { toDateValue } from '@icp/utils';
import ButtonUI from '../ButtonElement/ButtonUI';

function GanttActionCellRenderer(props) {
  const { task, ganttApiRef, deletePromisesRef, mapping: topMapping, isTreeDataSource } = props;

  const { t } = useTranslation(['icp-form-renderer']);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const canAddChild = Boolean(!isTreeDataSource ? topMapping.parent : task._dataSourceConfig.child);

  const handleAddSubTask = (event) => {
    event.stopPropagation();
    const subDataSourceConfig = !isTreeDataSource
      ? task._dataSourceConfig
      : task._dataSourceConfig.child;
    const subMapping = { ...topMapping, ...subDataSourceConfig.mapping };

    const parentFieldValue = resolveNestedValue({
      obj: subDataSourceConfig.parentFieldValue || ':id',
      currentData: task,
      // 暂时不提供 formData, context 等，新建子任务的时候不应该有这种需求
    });

    ganttApiRef.current.openDialog({
      _original: {
        [subMapping.parent]: parentFieldValue,
        [subMapping.start_date]: task[subMapping.start_date],
        [subMapping.end_date]: toDateValue(dayjs(task[subMapping.start_date]).add(1, 'day'), true),
        [subMapping.duration]: 1,
      },
      _dataSourceConfig: !isTreeDataSource ? task._dataSourceConfig : task._dataSourceConfig.child,
    });
    setAnchorEl(null);
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    ganttApiRef.current.openDialog(task);
    setAnchorEl(null);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    ganttApiRef.current.gantt.confirm({
      text: t('action.delete-confirm', { name: task.text }),
      callback: () => {
        // 删除 task 会自动级联删除所有的子 task 和所有相关的 link
        ganttApiRef.current.setLoading(true);
        deletePromisesRef.current = [];
        ganttApiRef.current.gantt.deleteTask(task.id);
        setTimeout(() => {
          Promise.all([...deletePromisesRef.current]).then(() => {
            ganttApiRef.current.setLoading(false);
          });
        }, 16);
      },
    });
    setAnchorEl(null);
  };

  return (
    <div className="gantt-action-cell">
      <ButtonUI
        type="text"
        size="small"
        icon="oct:kebab-horizontal"
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.target);
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        className="gantt-action-popover"
        onClose={() => setAnchorEl(null)}
      >
        {canAddChild ? (
          <ButtonUI type="text" icon="oct:plus" onClick={handleAddSubTask}>
            {t('gantt.new-sub-task', { ns: 'icp-form-renderer' })}
          </ButtonUI>
        ) : null}
        <ButtonUI type="text" icon="solar:pen-bold-duotone" onClick={handleEdit}>
          {t('edit', { ns: 'icp-common' })}
        </ButtonUI>
        <ButtonUI
          type="text"
          icon="solar:trash-bin-minimalistic-bold-duotone"
          danger={true}
          onClick={handleDelete}
        >
          {t('delete', { ns: 'icp-common' })}
        </ButtonUI>
      </Popover>
    </div>
  );
}

export default GanttActionCellRenderer;
