import '../../customerFeatures/pdfchat/PDFChatAI.css';
import { AIAgent, useDefaultFeedbackSettings } from '@icp/components';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { restApi } from '@icp/settings';
import { selectUserProfile } from '../../store/features/authSlice';
import useNonScalableViewport from '../../hooks/useNonScalableViewport';
import configuration from './configuration.json';

const SMALL_SCREEN_THRESHOLD = 512;

function PublicChat() {
  const { t } = useTranslation();
  const userProfile = useSelector(selectUserProfile);

  useNonScalableViewport(SMALL_SCREEN_THRESHOLD);

  useEffect(() => {
    document.getElementById('root').classList.add('pdf-chat-embedded');
    return () => {
      document.getElementById('root').classList.remove('pdf-chat-embedded');
    };
  }, []);

  const feedbackSettings = useDefaultFeedbackSettings({
    url: configuration.apiUrls.feedback,
    formTitle: t('pdf-chat.feedback-form-title'),
    formMessage: t('pdf-chat.feedback-form-message'),
  });

  const chatApi = {
    name: 'ceta-chat-kb',
    url: configuration.apiUrls.chat,
    stream: false,
    sendMessage({ prompt, agent }, signal) {
      return restApi
        .post(configuration.apiUrls.chat, { prompt, agentId: agent?.id }, { signal })
        .then((response) => {
          const { status, content, kb_items: kbItems } = response.result;
          if (status !== 0) {
            throw Error(content);
          }
          return { answer: content, source: kbItems };
        });
    },
  };

  return (
    <div className="pdf-chat-ai">
      <AIAgent
        userProfile={userProfile}
        chatConfiguration={{
          useAgents: true,
          chatApi,
          apiUrls: {
            getAgentList: configuration.apiUrls.getAgentList,
            getThreadList: configuration.apiUrls.getThreadList,
            getMessageList: configuration.apiUrls.getMessageList,
            getActionList: configuration.apiUrls.getActionList,
          },
        }}
        uiConfiguration={{
          inputToolbar: {
            hideModelPicker: true,
            hideSpeech: true,
          },
        }}
        feedbackSettings={feedbackSettings}
      />
    </div>
  );
}

export default PublicChat;
