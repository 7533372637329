import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import contextReducer from './features/contextSlice';
import formDataReducer from './features/formDataSlice';
import { permissionReducer } from './features/permissionSlice';
import fileStorageReducer from './features/fileStorageSlice';
import listeners from './features/listeners';
import { contextInitialState, formDataInitialState } from './initialState';
import { makeYupSchema } from '../utils';

const reducer = {
  context: contextReducer,
  permission: permissionReducer,
  fileStorage: fileStorageReducer,
  formData: formDataReducer,
};

export default function makeStore({
  disableDirty,
  isInDesign,
  isFetching,
  yupSchema: definedYupSchema,
  context,
  defaultValues,
  valueExpressions,
  onFieldValueChange,
  formApi,
  params,
}) {
  const yupSchema = definedYupSchema || makeYupSchema();

  const listenerMiddleware = createListenerMiddleware({
    extra: { yupSchema, onFieldValueChange, formApi },
  });

  listeners.forEach((listener) => listenerMiddleware.startListening(listener));

  return configureStore({
    reducer,
    preloadedState: {
      context: {
        ...contextInitialState,
        ...context,
      },
      formData: {
        ...formDataInitialState,
        disableDirty,
        isFetching,
        // 永远把 defaultValues 放进 store，当通过 retrieveUrl 获取到数据的时候，会将 api 返回的数据与 defaultValue 进行 merge
        initialValues: defaultValues || {},
        // 当没有 retrieveUrl 的时候才设置 default data 给表单，否则表单会通过 retrieveUrl 去获取数据，导致
        // 页面会先显示 default data，过一会儿再显示 retrieveUrl 的数据。
        values: (!isFetching && defaultValues) || {},
      },
    },
    devTools:
      process.env.NODE_ENV !== 'production' || window.ICP_REDUX_DEBUG
        ? {
            name: `${isInDesign ? 'FormDesigner-' : ''}FormRender-${
              context.formEntityDataId ||
              context.schemaId ||
              (context.layoutToken && `${context.formEntityToken}-${context.layoutToken}`) ||
              ''
            }`,
          }
        : false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { yupSchema, valueExpressions, params },
        },
        serializableCheck: {
          ignoredActions: [
            // 设置代理验证方法的action
            'formData/setFieldValidatorDelegate',
          ],
          ignoredPaths: [
            // 代理验证方法的Map
            'formData.validatorDelegates',
          ],
        },
      }).prepend(listenerMiddleware.middleware),
  });
}
