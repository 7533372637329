import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { translateEntireObj } from '@icp/i18n';
import { restApi } from '@icp/settings';
import { Icon } from '@icp/components';

const STORAGE_KEY = 'ICP_NOTICE_BOARD';

function getStorage() {
  return localStorage.getItem(STORAGE_KEY);
}

function setStorage(uuid) {
  return localStorage.setItem(STORAGE_KEY, uuid);
}

export default function NoticeBoard() {
  const [api, contextHolder] = notification.useNotification();
  const [conf, setConf] = useState(null);

  const { i18n } = useTranslation();

  useEffect(() => {
    restApi
      .get(`${window.ICP_PUBLIC_PATH}notice-board.config.json`, { skipResponseInterceptors: true })
      .then(setConf);
  }, []);

  useEffect(() => {
    if (!conf) return;
    if (!api) return;

    translateEntireObj(conf, { lng: i18n.language, force: true });

    const now = new Date();
    const { id, startTime, endTime, title, content, icon, style, closeable } = conf;
    if (startTime && new Date(startTime) > now) return;
    if (endTime && new Date(endTime) < now) return;
    if (id && getStorage() === String(id)) return;
    if (!title && !content) return;

    api.info({
      key: 'icp-notice-board-2b2ba94a-24ec-4de9-949b-fbaa2345ff26',
      style: {
        width: '100vw',
        ...style,
      },
      placement: 'top',
      duration: null,
      message: title,
      description: content,
      icon: icon ? <Icon size={18} {...icon} /> : null,
      closeIcon: closeable ? undefined : false,
      onClose: () => {
        setStorage(id);
      },
    });
  }, [conf, api, i18n.language]);

  return contextHolder;
}
