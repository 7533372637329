import { restApi, registerLocationChangedListener } from '@icp/settings';
import { parseJSON } from '@icp/utils';
import { useEffect, useRef, useState } from 'react';

// Map<String, Promise<Field[]>> key: <project-token>#@#<pbc-token>#@#<form-entity-token>
const LOADER_CACHE = new Map();
// Map<String, Field[]> key: <project-token>#@#<pbc-token>#@#<form-entity-token>
const FIELDS_CACHE = new Map();

registerLocationChangedListener(() => {
  LOADER_CACHE.clear();
  FIELDS_CACHE.clear();
});

async function fetchFieldList(projectToken, pbcToken, formEntityToken, signal) {
  const cacheKey = [projectToken, pbcToken, formEntityToken].join('#@#');
  if (LOADER_CACHE.has(cacheKey)) {
    return LOADER_CACHE.get(cacheKey);
  }

  const loader = restApi
    .get(`/form/api/v2/form-entity-field/list/${pbcToken}/${formEntityToken}/${projectToken}`, {
      signal,
    })
    .then((fields) => {
      fields.forEach((field) => {
        normalizeField(field);
      });
      return fields || [];
    })
    .catch((error) => {
      LOADER_CACHE.delete(cacheKey);
      throw error;
    });

  LOADER_CACHE.set(cacheKey, loader);

  return loader;
}

function normalizeField(field) {
  if (field.type === 'EDITABLE_GRID') {
    const fieldConfig = parseJSON(field.fieldConfigJson);
    field.referencePbc = fieldConfig.pbcToken;
    field.referenceEntity = fieldConfig.formEntityToken;
  }
}

async function fetchFields(projectToken, pbcToken, formEntityToken, signal) {
  const cacheKey = [projectToken, pbcToken, formEntityToken].join('#@#');
  if (FIELDS_CACHE.has(cacheKey)) {
    return FIELDS_CACHE.get(cacheKey);
  }

  const fields = await fetchFieldList(projectToken, pbcToken, formEntityToken, signal);
  FIELDS_CACHE.set(cacheKey, fields);

  return fields;
}

async function prefetchFieldsByValue(value, projectToken, pbcToken, formEntityToken, signal) {
  if (!value?.length) return;

  const tasks = value.map(async (key) => {
    const kp = key.split('.');

    let currentPbcToken = pbcToken;
    let currentFormEntityToken = formEntityToken;

    for (let i = 0; i < kp.length; i++) {
      const keyToLoad = kp[i];

      // eslint-disable-next-line no-await-in-loop
      const fields = await fetchFields(
        projectToken,
        currentPbcToken,
        currentFormEntityToken,
        signal,
      );

      const field = fields.find((x) => x.token === keyToLoad);

      if (!field) break;
      currentPbcToken = field.referencePbc;
      currentFormEntityToken = field.referenceEntity;
    }
  });

  await Promise.allSettled(tasks);
}

function isFieldDWable(field) {
  // ACL SELECT 使用reference系列参数, EDITABLE_GRID 使用fieldConfigJson
  return (
    [
      'ACL',
      'SELECT',
      'EDITABLE_GRID', // normalized
    ].includes(field.type) &&
    field.referencePbc &&
    field.referenceEntity
  );
}

function convertToTreeData(projectToken, fields, valuePrefix = '') {
  const treeData = fields.map((field) => {
    const isLeaf = !isFieldDWable(field);

    const node = {
      value: `${valuePrefix}${field.token}`,
      label: field.name,
      isLeaf,
      field,
      loadChildren: async () => {
        const subFields = await fetchFields(
          projectToken,
          field.referencePbc,
          field.referenceEntity,
        );
        node.children = convertToTreeData(projectToken, subFields, `${valuePrefix}${field.token}.`);
      },
    };

    return node;
  });

  return treeData;
}

function resolveValue(valueInStringArray, projectToken, pbcToken, formEntityToken) {
  return valueInStringArray.map((singleStringValue) => {
    return singleStringValue.split('.').reduce(
      (accumulated, targetFieldToken) => {
        const { currentPbcToken, currentFormEntityToken } = accumulated;
        if (!currentPbcToken || !currentFormEntityToken) {
          return {
            currentPbcToken: null,
            currentFormEntityToken: null,
            results: [...accumulated.results, { token: targetFieldToken }],
          };
        }
        const fields = FIELDS_CACHE.get(
          [projectToken, currentPbcToken, currentFormEntityToken].join('#@#'),
        );
        const field = fields?.find((x) => x.token === targetFieldToken);
        if (!field) {
          return {
            currentPbcToken: null,
            currentFormEntityToken: null,
            results: [...accumulated.results, { token: targetFieldToken }],
          };
        }

        return {
          currentPbcToken: field.referencePbc,
          currentFormEntityToken: field.referenceEntity,
          results: [...accumulated.results, field],
        };
      },
      {
        currentPbcToken: pbcToken,
        currentFormEntityToken: formEntityToken,
        results: [],
      },
    ).results;
  });
}

function extractTreeDataKeys(treeData) {
  return (
    treeData
      ?.filter((x) => !x.isLeaf && x.children)
      ?.flatMap((x) => [x.value, ...extractTreeDataKeys(x.children)]) || []
  );
}

// 初始只加载root层和initialValue使用到的，其它全部懒加载
export function useLoadTreeData({ initialValue, projectToken, pbcToken, formEntityToken }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const initialValueRef = useRef(initialValue || []);

  useEffect(() => {
    if (!projectToken || !pbcToken || !formEntityToken) {
      return () => {};
    }
    const controller = new AbortController();
    const { signal } = controller;

    setLoading(true);

    fetchFields(projectToken, pbcToken, formEntityToken, signal)
      .then(async (rootFields) => {
        await prefetchFieldsByValue(
          initialValueRef.current,
          projectToken,
          pbcToken,
          formEntityToken,
          signal,
        );

        const initialTreeData = convertToTreeData(projectToken, rootFields);

        setData({
          resolveFieldInValue: (valueInStringArray) =>
            resolveValue(valueInStringArray, projectToken, pbcToken, formEntityToken),
          resolveLabelInValue: (valueInStringArray) =>
            resolveValue(valueInStringArray, projectToken, pbcToken, formEntityToken)
              //
              .map((aof) => aof.map((field) => field.name ?? field.token).join(' > ')),
          treeData: initialTreeData,
          loadedKeys: extractTreeDataKeys(initialTreeData),
          loadData: async (node) => {
            await node.loadChildren();
            setData((prev) => {
              return {
                ...prev,
                treeData: [...prev.treeData],
                loadedKeys: extractTreeDataKeys(prev.treeData),
              };
            });
          },
        });
      })
      .finally(() => setLoading(false));

    return () => {
      controller.abort();
    };
  }, [projectToken, pbcToken, formEntityToken]);

  return [data, loading];
}
