import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import listeners from './features/listeners';
import agentSlice from './features/agentSlice';

const reducer = {
  [agentSlice.name]: agentSlice.reducer,
};

export default function makeStore({ apiUrls }) {
  const listenerMiddleware = createListenerMiddleware();

  listeners.forEach((listener) => listenerMiddleware.startListening(listener));

  const store = configureStore({
    reducer,
    // preloadedState: {},
    devTools:
      process.env.NODE_ENV !== 'production' || window.ICP_REDUX_DEBUG ? { name: `Agent` } : false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { apiUrls },
        },
      }).prepend(listenerMiddleware.middleware),
  });

  const clearListeners = () => {
    listenerMiddleware.clearListeners();
  };

  return [store, clearListeners];
}
