import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import Tab from '../Tabs/Tab';
import Icon from '../Icon';

const iconMap = {
  Table: <Icon name="component-Table" size={16} />,
  Gantt: <Icon name="material:view-timeline-outlined" size={18} />,
};

function CombinedViewSwitch(props) {
  const { components, currentView, setCurrentView } = props;

  return (
    <Tabs value={currentView} onChange={setCurrentView} style={{ borderBottom: 0 }}>
      {components.map((item) => {
        const { name, label } = item;
        return (
          <Tab key={name} className="icp-combined-view-tab" value={name}>
            {iconMap[name]}
            {label}
          </Tab>
        );
      })}
    </Tabs>
  );
}

CombinedViewSwitch.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  currentView: PropTypes.string,
  setCurrentView: PropTypes.func,
};

export default CombinedViewSwitch;
