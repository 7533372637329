import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { useMemo, useState } from 'react';
import { Icon, Loading } from '@icp/components';
import { get } from 'lodash-es';
import { FormHelperTextMaterial } from '../../FormHelperText';
import { getMaterialDisplayLabel, toSimpleValue } from './utils';
import ItemField from './ItemField';

const loadingIndicator = 'lazy-loading-indicator';

function DownIcon(props) {
  return <Icon name="oct:chevron-down" size={18} {...props} />;
}

function SelectMaterial(props) {
  const {
    keyPath,
    id,
    title,
    value,
    componentProps,
    validation,
    disabled,
    readonly,
    status,
    helpers,
    onChange,
    onTouchChanged,

    showSearch,
    loading,
    lazyLoading,
    moreLoading,
    useClientSideFilter,
    options,
    multiple,
    mapping,
    itemField,
    onSearch,
    onScroll,
  } = props;

  const useSimpleSelect = !showSearch && !lazyLoading;

  const [innerSearchText, setInnerSearchText] = useState('');

  const simpleValue = useMemo(() => {
    return toSimpleValue(value);
  }, [value]);

  const objValue = useMemo(() => {
    if (multiple) {
      return []
        .concat(simpleValue)
        .filter((v) => v !== undefined && v !== null)
        .map((v) => {
          return options.find((op) => op[mapping.value] === v) || { [mapping.value]: v };
        });
    }
    return simpleValue !== undefined && simpleValue !== null
      ? options.find((op) => op[mapping.value] === simpleValue) || { [mapping.value]: simpleValue }
      : null;
  }, [multiple, options, mapping.value, simpleValue]);

  const optionsWithLoading = useMemo(() => {
    if (lazyLoading && options?.length && moreLoading) {
      return [...options, { type: loadingIndicator }];
    }
    return options;
  }, [moreLoading, lazyLoading, options]);

  if (useSimpleSelect) {
    return (
      <FormControl required={validation?.required} fullWidth={true} error={status === 'error'}>
        <InputLabel id={`${id}-label`}>{title}</InputLabel>
        <Select
          {...componentProps}
          labelId={`${id}-label`}
          id={id}
          // 直接传 undefined 会抛 warning，material 推荐用空字符串。如果是多选的情况，SelectElement 保证了至少一定会传空数组
          value={simpleValue ?? ''}
          label={title}
          multiple={multiple}
          required={validation?.required}
          disabled={disabled}
          readOnly={readonly}
          IconComponent={DownIcon}
          onChange={(event) => onChange(event.target.value)}
          onBlur={onTouchChanged}
          endAdornment={
            loading ? (
              <CircularProgress
                color="inherit"
                size={16}
                style={{ flex: 'none', marginRight: 24 }}
              />
            ) : null
          }
          renderValue={
            multiple
              ? (selected) => {
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((v) => {
                        return (
                          <Chip
                            key={v}
                            size="small"
                            label={getMaterialDisplayLabel({ options, value, v, mapping })}
                          />
                        );
                      })}
                    </Box>
                  );
                }
              : (v) => getMaterialDisplayLabel({ options, value, v, mapping })
          }
        >
          {(options || []).map((item) => {
            return (
              <MenuItem key={item[mapping.value]} value={item[mapping.value]}>
                {itemField ? (
                  <ItemField keyPath={keyPath} itemField={itemField} option={item} />
                ) : (
                  get(item, mapping.label)
                )}
              </MenuItem>
            );
          })}
        </Select>
        {!readonly && !helpersIsEmpty(helpers) ? (
          <FormHelperText>
            <FormHelperTextMaterial helpers={helpers} />
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }

  const handleAutocompleteChange = (event, newSelected) => {
    if (loading || moreLoading) {
      // 家在过程中不触发 change，否则在加载的时候按键盘删除多选的选项会导致找不到 option 报错。
      return;
    }
    // 多选的时候 Autocomplete 会吧新选中的 option 和之前的 value 放一起传回来，类似 [...value, op] 的操作，
    // 所以以前的 value 都是 id，新的 op 是整个 option 对象
    const toValueItem = (v) => (typeof v === 'object' && v ? v[mapping.value] : v);
    if (Array.isArray(newSelected)) {
      onChange(newSelected.map(toValueItem));
    } else {
      onChange(toValueItem(newSelected));
    }
  };

  const handleSearch = (event, newInputValue, reason) => {
    if (reason !== 'reset' && !useClientSideFilter) {
      setInnerSearchText(newInputValue);
      onSearch(newInputValue);
    }
  };

  const handleClose = () => {
    setInnerSearchText('');
    if (!useClientSideFilter) {
      onSearch('');
    }
  };

  return (
    <Autocomplete
      fullWidth={true}
      id={id}
      value={objValue}
      multiple={multiple}
      disabled={disabled}
      readOnly={readonly}
      // mapping.label 可以是 a[0].b 的形式
      getOptionLabel={(option) => get(option, mapping.label) ?? ''}
      options={optionsWithLoading || []}
      loading={loading}
      disableCloseOnSelect={multiple}
      limitTags={10}
      popupIcon={<DownIcon />}
      clearIcon={<Icon name="oct:x" size={18} />}
      onChange={handleAutocompleteChange}
      onBlur={onTouchChanged}
      // lazyLoading searchText 改变导致组件 options 改变重新渲染的时候，autocomplete 会自动清空 inputValue
      // 触发 reason 是 'reset' 的 onInputChange 事件， 所以这里必须使用 controlled 的值来强制显示
      inputValue={!useClientSideFilter ? innerSearchText : undefined}
      onInputChange={handleSearch}
      onClose={handleClose}
      ListboxProps={{
        onScroll: lazyLoading ? onScroll : undefined,
      }}
      getOptionDisabled={(option) => option.type === loadingIndicator}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((v, index) => {
          if (v === undefined || v === null) {
            return null;
          }
          return (
            <Chip
              size="small"
              label={getMaterialDisplayLabel({ options, value, v: v[mapping.value], mapping })}
              {...getTagProps({ index })}
            />
          );
        });
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={title}
            required={validation?.required}
            error={status === 'error'}
            inputProps={{
              ...params.inputProps,
              // 禁止浏览器的 autocomplete，否则组件会出错停止工作
              autoComplete: 'off',
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={16} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            helperText={
              !readonly && !helpersIsEmpty(helpers) ? (
                <FormHelperTextMaterial helpers={helpers} />
              ) : null
            }
          />
        );
      }}
      renderOption={(p, option) => {
        const reactKey = option[mapping.value];
        if (option.type === loadingIndicator) {
          return (
            <li {...p} key={reactKey}>
              <Loading size={16} centered={false} delayed={false} />
            </li>
          );
        }
        return (
          <li {...p} key={reactKey}>
            {itemField ? (
              <ItemField keyPath={keyPath} itemField={itemField} option={option} />
            ) : (
              get(option, mapping.label)
            )}
          </li>
        );
      }}
      filterOptions={(ops, { inputValue }) => {
        if (!useClientSideFilter) {
          return ops;
        }
        return ops.filter((option) => {
          return (get(option, mapping.label) ?? '')
            .toLowerCase()
            .includes((inputValue || '').toLowerCase());
        });
      }}
    />
  );
}

SelectMaterial.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      }),
    ),
  ]),
  componentProps: PropTypes.shape({}),
  multiple: PropTypes.bool,
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    // Other key map to save
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  showSearch: PropTypes.bool,
  lazyLoading: PropTypes.bool,
  itemField: PropTypes.shape({}),
  fieldTitleProps: PropTypes.shape({}),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
  loading: PropTypes.bool,
  moreLoading: PropTypes.bool,
  useClientSideFilter: PropTypes.bool,
  onSearch: PropTypes.func,
  onScroll: PropTypes.func,
};

export default SelectMaterial;
