import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getLocationChangedListeners } from '@icp/settings';

export default function Effects({ appChildren, onLocationChanged }) {
  const ref = useRef({});
  ref.current.onLocationChanged = onLocationChanged;

  const location = useLocation();

  useEffect(() => {
    ref.current.onLocationChanged?.(location);
    getLocationChangedListeners().forEach((fn) => fn(location));
  }, [location]);

  return (
    <>
      <Outlet />
      {appChildren}
    </>
  );
}

export const EffectsPropTypes = {
  onLocationChanged: PropTypes.func,
  appChildren: PropTypes.node,
};

Effects.propTypes = EffectsPropTypes;
