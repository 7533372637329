import { useCallback, useMemo, useState } from 'react';
import { restApi } from '@icp/settings';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

/* for PDF Chat in saas-ui and CETA Assistant in platform */
export default function useDefaultFeedbackSettings(props) {
  const { url, params = {}, formTitle, formMessage } = props;
  const { t } = useTranslation(['icp-components']);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [userFeedback, setUserFeedback] = useState('');

  const addFeedback = useCallback(
    (isLike, parameters) => {
      const payload = { ...parameters, time: new Date().toISOString(), isLike };
      return restApi.post(url, payload, { params });
    },
    [params, url],
  );

  return useMemo(
    () => ({
      enabled: true,
      forms: {
        dislike: {
          title: formTitle,
          body: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              {formMessage && (
                <div style={{ color: '#7b8495', fontSize: '12px' }}>{formMessage}</div>
              )}
              <Input
                placeholder={t('ai-agent.feedback.name')}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <Input
                placeholder={t('ai-agent.feedback.email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                placeholder={t('ai-agent.feedback.phone')}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <Input.TextArea
                style={{ height: 80, resize: 'none' }}
                placeholder={t('ai-agent.feedback.userFeedback')}
                value={userFeedback}
                onChange={(e) => setUserFeedback(e.target.value)}
              />
            </div>
          ),
        },
      },
      handlers: {
        like: ({ question, answer, agentId }) => {
          return addFeedback(true, { question, answer, agentId });
        },
        dislike: ({ question, answer, agentId }) => {
          return addFeedback(false, {
            question,
            answer,
            userName,
            email,
            phone,
            userFeedback,
            agentId,
          }).then(() => setUserFeedback(''));
        },
      },
    }),
    [addFeedback, email, formMessage, formTitle, phone, t, userFeedback, userName],
  );
}
