import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import contextReducer from './features/contextSlice';
import formEntityReducer from './features/formEntitySlice';
import pageLayoutReducer from './features/pageLayoutSlice';
import permissionReducer from './features/permissionSlice';
import listeners from './features/listeners';

const reducer = {
  context: contextReducer,
  permission: permissionReducer,
  formEntity: formEntityReducer,
  pageLayout: pageLayoutReducer,
};

export default function makeStore({
  typeName,
  restApi,
  pbcId,
  formEntityId,
  isInnerEntity,
  preloadedState,
  singleLayout,
  readonly,
  userProfile,
  userPermissionMap,
  //
}) {
  if (isInnerEntity) {
    singleLayout = true;
  }

  const listenerMiddleware = createListenerMiddleware();

  listeners.forEach((listener) => listenerMiddleware.startListening(listener));

  return configureStore({
    reducer,
    preloadedState: preloadedState || {
      context: {
        typeName,
        readonly,
        pbcId,
        formEntityId,
        isInnerEntity,
        singleLayout,
        userProfile,
        userPermissionMap,
      },
    },
    devTools:
      process.env.NODE_ENV !== 'production' || window.ICP_REDUX_DEBUG
        ? { name: `FormDesigner-${formEntityId ?? 'new'}` }
        : false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { restApi },
        },
      }).prepend(listenerMiddleware.middleware),
  });
}
