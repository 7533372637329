import PropTypes from 'prop-types';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Button } from 'antd';
import { PlusIcon } from '@primer/octicons-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  HELPER_TEXT_TYPES,
  resolveInitialValues,
  selectContext,
  selectFieldInitialValue,
  selectValues,
} from '@icp/form-renderer-core';
import { debounce, randomNumber, removeUndefined } from '@icp/utils';
import { isEqual } from 'lodash-es';
import { useSelector, useStore } from '../../store';
import TableElement from '../TableElement';
import FieldTitle from '../../FieldTitle';
import {
  extractValueExpressions,
  findFirstEditableColKey,
  formatEditorColumnDefs,
  getChangedData,
  getRowData,
} from './utils';
import { useClassName } from '../../hooks';
import FormHelperText from '../../FormHelperText';
import { isDataValid, makeTableYupSchema } from './validation';
import { processCellForClipboard, processCellFromClipboard } from './processClipboard';
import { useElementDecorator, useIsInDesign } from '../../FormRenderCtx';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import useEditableTableAsyncValue from './useEditableTableAsyncValue';
import { processValueExpression } from './processValueExpression';
import { useCurrentData } from '../../currentDataCtx';

const EditableTableElement = forwardRef(function EditableTableElement(props, ref) {
  const [updatedComponentProps, setComponentUpdatedProps] = useState({});

  const {
    keyPath,
    id,
    className: classNameProp,
    style,
    title,
    componentProps = {},
    fieldTitleProps,
    value,
    onChange,
    setValidatorDelegate,
    disabled,
    readonly,
    validation,
    helpers,
  } = props;

  const componentPropsRef = useRef(null);
  componentPropsRef.current = { ...componentProps, ...removeUndefined(updatedComponentProps) };

  const {
    simpleMode = false,
    defaultColDef,
    columnDefs: columnDefsProp,
    dataSource, // 非法配置，从 componentProps 里摘取出来不传给 TableElement
    dataUrl: dataUrlProp,
    defaultValueDataUrl: defaultValueDataUrlProp,
    transformDataResponse,
    transformDefaultValueResponse,
    dataFilters,
    canAddRow = true,
    newRowDefaultValues,
    maxRowCount = Infinity,
    canDeleteRow = true,
    orderField,
    ...otherComponentProps
  } = componentPropsRef.current;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const routerParams = useParams();
  const store = useStore();
  const { t } = useTranslation(['icp-components']);
  const currentData = useCurrentData();

  const initialValue = useSelector(selectFieldInitialValue(id));

  const [refresh, setRefresh] = useState({});

  const nodeRef = useRef(null);
  const gridRef = useRef(null);
  const currentRowData = useRef(null);

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const columnDefs = useMemo(() => {
    return formatEditorColumnDefs(columnDefsProp, defaultColDef, disabled, readonly);
  }, [columnDefsProp, defaultColDef, disabled, readonly]);

  const yupSchema = useMemo(() => {
    return makeTableYupSchema(columnDefs, defaultColDef, disabled, readonly);
  }, [columnDefs, defaultColDef, disabled, readonly]);

  const valueExpressions = useMemo(() => {
    return extractValueExpressions(columnDefs);
  }, [columnDefs]);

  const { fetching } = useEditableTableAsyncValue({
    refresh,
    id,
    dataUrlProp,
    defaultValueDataUrlProp,
    dataFilters,
    transformDataResponse,
    transformDefaultValueResponse,
    orderField,
  });

  // null to display loading
  const [rowData, setRowData] = useState(currentRowData.current || (fetching ? null : []));

  useEffect(() => {
    if (Array.isArray(value) && value !== currentRowData.current) {
      // 可编辑表格会 mutable 直接修改 rowData 不能直接用 redux 的数据
      setRowData(JSON.parse(JSON.stringify(value)));
    }
  }, [value]);

  // 组件卸载时清空 redux 里的数据。因为 EditableTable 往后端提交的数据格式 { inserted, updated, deleted } 是
  // 通过 getChangedData 转换的到的，非此格式 api 会报错，此函数必须要在组件被渲染的时候才能调用到。
  // 解决方法是决定组件不可见的时候不应该往后端提交去修改 EditableTable 的数据。
  useEffect(() => {
    return () => onChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addMultiRows = useCallback(
    (rows) => {
      const newRowsData = [];

      for (const row of rows) {
        const newRow = { ...row };
        // 为了支持外部通过 gridApi 直接调用 applyTransaction({ update: })，需要保证行数据有 id
        if (!newRow.id && !simpleMode) {
          newRow.id = `inserted-${Date.now()}-${randomNumber()}`;
        }
        newRowsData.push(newRow);
      }

      // insert new row to ag-grid
      gridRef.current.gridApi.applyTransaction({ add: newRowsData });

      // ensure visible
      const rowIndex = gridRef.current.gridApi.getDisplayedRowCount() - 1;

      return gridRef.current.gridApi.ensureIndexVisible(rowIndex, 'bottom');
    },
    [simpleMode],
  );

  const tableApi = useMemo(() => {
    return {
      get node() {
        return nodeRef.current;
      },
      get gridApi() {
        // tableApi 会作为属性往 TableElement 传，withFieldWrapper 会遍历所有属性进行翻译，所以在组件渲染之前就会访问到 gridApi
        return gridRef.current?.gridApi;
      },
      getComponentProps: () => componentPropsRef.current,
      updateComponentProps: (obj) => {
        setComponentUpdatedProps((old) => {
          return { ...old, ...obj };
        });
      },
      refresh: () => setRefresh({}),
      isSimpleMode: () => simpleMode,
      getRowData: () => getRowData(gridRef.current.gridApi),
      getOldRowData: () => initialValue, // simple mode 不会备份 oldRowData，rowData 永远和 store 里的相同
      getChangedData: () => getChangedData(gridRef.current.gridApi, initialValue),
      addRow: (rows) => addMultiRows([].concat(rows).filter(Boolean)),
      updateRow: (rows) => {
        return gridRef.current.gridApi.applyTransaction({
          update: [].concat(rows).filter(Boolean),
        });
      },
      removeRow: (rows) => {
        return gridRef.current.gridApi.applyTransaction({
          remove: [].concat(rows).filter(Boolean),
        });
      },
    };
  }, [addMultiRows, initialValue, simpleMode]);

  useImperativeHandle(ref, () => tableApi, [tableApi]);

  // 代理表单验证方法，在提交表单的时候再次验证
  useMemo(() => {
    setValidatorDelegate?.((base) => ({
      ...base,
      // 注册运行时表单验证代理要求组件自身有validation属性
      // EditableTable存在自身无validation属性但columns中有的情况
      // 因此需确保组件自身有validation对象让columns验证器生效
      [Symbol.for('validation')]: {
        ...base?.[Symbol.for('validation')],
        validation: {
          ...base?.[Symbol.for('validation')]?.validation,
        },
      },
      isEmpty: () => {
        return !currentRowData.current?.length;
      },
      isDataValid: () => {
        const finalColumnDefs =
          gridRef.current?.gridApi.getColumns().map((col) => col.getColDef()) ?? [];
        return isDataValid({
          yupSchema,
          columnDefs: finalColumnDefs,
          rowData: currentRowData.current ?? [],
        });
      },
    }));
  }, [setValidatorDelegate, yupSchema]);

  const triggerChange = () => {
    if (!gridRef.current?.gridApi) {
      return;
    }
    currentRowData.current = getRowData(gridRef.current.gridApi);

    if (!isEqual(value, currentRowData.current)) {
      onChange(currentRowData.current);
      // 不能在这里触发 onTouchChanged，不然新 add 一个空行立即就会触发验证报错
    }
  };

  const handleAddRow = () => {
    const newRow = resolveInitialValues({
      defaultValues: newRowDefaultValues,
      currentData,
      formData: selectValues(store.getState()),
      context: selectContext(store.getState()),
      params: routerParams,
    });

    // 不加多层 setTimeout ag-grid 会 startEditing 然后立马退出 editing，暂时没找到更好的解决办法。
    setTimeout(() => {
      addMultiRows([newRow]);

      // start editing cell on last row
      const rowIndex = gridRef.current.gridApi.getDisplayedRowCount() - 1;
      const colKey = findFirstEditableColKey(gridRef);

      setTimeout(() => {
        if (colKey) {
          gridRef.current.gridApi.startEditingCell({ rowIndex, colKey });
        }
      }, 500);
    }, 16);
  };

  const handleDeleteRow = (rowNode) => {
    gridRef.current.gridApi.applyTransaction({ remove: [rowNode.data] });
  };

  // 16ms 只是为了阻止复制粘贴多个 cell 触发多次
  const debouncedTriggerChange = debounce(() => {
    triggerChange();
  }, 16);

  const handleCellValueChanged = (event) => {
    processValueExpression(event, valueExpressions);
    debouncedTriggerChange();
  };

  // 编辑 cell 的时候不会触发，
  // 初次加载、直接调用 api applyTransaction 会触发。
  const handleRowDataUpdated = () => {
    triggerChange();
  };

  const getContextMenuItems = (params) => {
    if (!params.node) {
      return params.defaultItems;
    }

    const pasteIndex = (params.defaultItems || []).indexOf('paste');

    const newMenus = [...params.defaultItems];

    if (!disabled && !readonly && canDeleteRow) {
      newMenus.splice(pasteIndex + 1, 0, 'separator', {
        name: t('table.delete-row'),
        icon: `<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>`,
        action: () => handleDeleteRow(params.node),
      });
    }

    return newMenus;
  };

  const processCellForClipboardCallback = useCallback(
    (params) => {
      return processCellForClipboard(params, store, routerParams);
    },
    [routerParams, store],
  );

  const processCellFromClipboardCallback = useCallback(
    (params) => {
      return processCellFromClipboard(params, store, routerParams);
    },
    [routerParams, store],
  );

  const handleDragEnd = (params) => {
    if (!orderField) return;

    params.api.forEachNode((rowNode, i) => {
      const newOrder = i + 1;
      const oldOrder = rowNode.data[orderField];
      if (String(newOrder) !== String(oldOrder)) {
        if (params.api.getColumn(orderField)) {
          rowNode.setDataValue(orderField, newOrder);
        }
      }
    });
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx('editable-table-element', 'input-element', 'form-element', className)}
        style={style}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        <div>
          <TableElement
            noElementDecorator={true}
            keyPath={keyPath}
            className={classNameComp}
            componentProps={{
              suppressSaveSetting: true,
              pagination: false,
              // TODO，下次把 fuzzy search 移到左边去就可以直接用 suppressToolbarActions 一个参数了
              suppressAddButton: true,
              suppressDeleteButton: true,
              suppressColumnSelect: true,
              suppressToolbarRefresh: true,
              suppressTableSetting: true,
              suppressFullscreen: true,
              suppressFavoriteView: true,
              suppressExcelExport: true,
              suppressCsvExport: true,
              suppressContextMenu: isInDesign,
              fuzzySearchOpen: true,
              enableCellTextSelection: false,
              cellSelection: true,
              rowDragManaged: true,
              // undefined 会覆盖 TableElement 自带的 getRowId
              getRowId: simpleMode ? undefined : (params) => String(params.data.id),
              // TODO, add / remove row cannot undo
              // undoRedoCellEditing: true,
              // undoRedoCellEditingLimit: 20,
              ...otherComponentProps,
              // 以下配置不允许从 componentProps 覆盖
              tableContext: tableApi, // 覆盖 TableElement 自身暴露给子元素的 tableContext
              defaultColDef,
              columnDefs,
              rowData,
              rowModelType: 'clientSide',
              getContextMenuItems,
              onCellValueChanged: handleCellValueChanged,
              onRowDataUpdated: handleRowDataUpdated,
              onRowDragEnd: handleDragEnd,
              processCellForClipboard: processCellForClipboardCallback,
              processCellFromClipboard: processCellFromClipboardCallback,
              context: { yupSchema },
            }}
            ref={gridRef}
          />
          {!disabled && !readonly && canAddRow && (value?.length || 0) < maxRowCount ? (
            <Button className="add-new-row-button" type="primary" onClick={handleAddRow}>
              <PlusIcon size={16} />
              {t('table.add-new-row')}
            </Button>
          ) : null}
          <FormHelperText helpers={helpers} />
        </div>
      </div>
    </ElementDecorator>
  );
});

EditableTableElement.propTypes = {
  fieldApi: PropTypes.func,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 如果设置 `true`, 在提交的时候全部发送给请求；
     * 如果设置 `false`，在提交的时候会自动识别成 { inserted, updated, deleted } 的增量修改模式。
     * @default false
     */
    simpleMode: PropTypes.bool,
    /**
     * 表格的列的定义，支持 ag-grid 所有的 columnDefs
     */
    columnDefs: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        // all other ag-grid column def properties
      }),
    ),
    /**
     * ag-grid 默认列的属性
     */
    defaultColDef: PropTypes.shape({}),
    /**
     * 转换从 `dataUrl` 获取到的数据，使用 eval 表达式，this 指向获取到的数据
     */
    transformDataResponse: PropTypes.string,
    /**
     * 通过 `dataUrl` 一次性获取所有表格数据
     */
    dataUrl: PropTypes.string,
    /**
     * 转换从 `defaultValueDataUrl` 获取到的数据，使用 eval 表达式，this 指向获取到的数据
     */
    transformDefaultValueResponse: PropTypes.string,
    /**
     * 表格默认值, 加载后放在inserted内
     */
    defaultValueDataUrl: PropTypes.string,
    /**
     * 请求数据源隐藏的固定 filter 条件，无法通过界面进行改变
     */
    dataFilters: PropTypes.shape({}),
    /**
     * 是否支持增加行
     * @default true
     * // TODO，先支持 bool 型，如果需要支持 dataPredicate，需要跟后端沟通。
     */
    canAddRow: PropTypes.bool,
    /**
     * 新增行的默认值
     */
    newRowDefaultValues: PropTypes.shape({}),
    /**
     * 可增加的最大行数
     */
    maxRowCount: PropTypes.number,
    /**
     * 是否支持删除行
     */
    canDeleteRow: PropTypes.bool,
    // all other TableElement componentProps
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  value: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  setValidatorDelegate: PropTypes.func,
  // For EditableTable, disabled look the same as readonly
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
EditableTableElement.displayName = 'EditableTable';

export default withFieldWrapper(EditableTableElement);
