import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

function CustomDateComponent(props) {
  const { date, onDateChange, filterParams } = props;

  const { showTime, format, readOnly } = filterParams || {};

  const handleChange = (newValue) => {
    let newDate = null;

    if (newValue) {
      // startOf('day') set time to 00:00:00
      newDate = showTime ? newValue.toDate() : newValue.startOf('day').toDate();
    }

    onDateChange(newDate);
  };

  return (
    <DatePicker
      className="ag-input-wrapper icp-custom-date-component"
      // ag-custom-component-popup 是 ag-grid 提供的阻止 popup 点击提前关闭的方法
      popupClassName="ag-custom-component-popup"
      value={date ? dayjs(date) : undefined}
      format={format}
      showTime={showTime}
      onChange={handleChange}
      style={{ height: 24, fontSize: 14 }}
      disabled={readOnly}
    />
  );
}

CustomDateComponent.propTypes = {
  date: PropTypes.instanceOf(Date),
  filterParams: PropTypes.shape({
    format: PropTypes.string,
    /**
     * antd DatePicker 的 `showTime` 属性
     * @default false
     */
    showTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    /**
     * onChange 的时候是否去除日期的 time zone 信息
     * @default false
     */
    noTimeZone: PropTypes.bool,
    readOnly: PropTypes.bool,
  }),
  onDateChange: PropTypes.func,
};

export default CustomDateComponent;
