import Axios from 'axios';

const { isCancel } = Axios;

export { isCancel };

const holder = {};

const restApiDelegate = {};

const messageDelegate = {};

let hasSetup = false;

export function setup({
  restApi,
  appConfig,
  extensionComponents,
  //
}) {
  if (hasSetup) {
    console.error('Setup method should be run only once.');
    return;
  }

  if (!restApi) {
    throw Error('Fatal Error: restApi is required!');
  }

  holder.restApi = restApi;
  holder.appConfig = appConfig;
  holder.extensionComponents = extensionComponents;

  Object.assign(restApiDelegate, restApi);

  hasSetup = true;
}

export function setupMessage(message) {
  holder.message = message;
  Object.assign(messageDelegate, message);
}

export { restApiDelegate as restApi };
export { messageDelegate as message };

export function setAppConfig(appConfig) {
  holder.appConfig = appConfig;
}

export function getAppConfig() {
  return holder.appConfig;
}

export function getExtensionComponents() {
  return holder.extensionComponents;
}

export function shouldTranslateByDefault(appConfig = holder.appConfig) {
  return appConfig?.i18n?.allowedLanguages?.length > 1;
}

export function getAppTimeZone() {
  return holder.appConfig?.timeZone;
}

export function setUserProfile(userProfile) {
  holder.userProfile = userProfile;
}

export function getUserProfileTimeZone() {
  return holder.userProfile?.timeZone;
}

export function getCascadedTimeZone() {
  return getUserProfileTimeZone() || getAppTimeZone();
}

export function getAppUserIdleTime() {
  return holder.appConfig?.userIdleTime;
}

export function getUserProfileUserIdleTime() {
  return holder.userProfile?.userIdleTime;
}

export function getCascadedUserIdleTime() {
  return getUserProfileUserIdleTime() || getAppUserIdleTime();
}

const locationChangedListeners = new Set();

export function registerLocationChangedListener(listener) {
  locationChangedListeners.add(listener);

  const dispose = () => {
    locationChangedListeners.delete(listener);
  };

  return dispose;
}

export function getLocationChangedListeners() {
  return locationChangedListeners;
}
