// 循环解析多个 data source 配置的 dataUrl 和 dataFilters
import {
  dataSourceToDataUrl,
  resolveConditionalProperty,
  resolveDataFilters,
  selectContext,
  selectValues,
} from '@icp/form-renderer-core';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import { isEqual } from 'lodash-es';
import { immutableSet, resolveVariablePattern } from '@icp/utils';
import { useCurrentData } from '../currentDataCtx';
import { useStore } from '../store';
import { useDependencies } from './index';

export default function useMultiDataSourceConfig(multiDataSourceProp) {
  const store = useStore();
  const context = selectContext(store.getState());
  const params = useParams();
  const currentData = useCurrentData();

  const oldProp = useRef(null);
  const memorized = useRef(null);

  if (!isEqual(multiDataSourceProp, oldProp.current)) {
    oldProp.current = multiDataSourceProp;
    memorized.current = multiDataSourceProp;
  }

  let depIds = [];

  (multiDataSourceProp || []).forEach((config, index) => {
    const { dataSource, dataUrl, dataFilters } = config;

    const dataUrlConfig = dataSource ? dataSourceToDataUrl(dataSource, context) : dataUrl;
    // conditional match a url, match first
    const [dataUrlMatched, dataUrlDepIds] = resolveConditionalProperty({
      value: dataUrlConfig,
      currentData,
      store,
      params,
    });
    const dataUrlResolved = resolveVariablePattern({
      pattern: dataUrlMatched,
      currentData,
      formData: selectValues(store.getState()),
      context,
      params,
      failWhenNoVariableValue: true,
    });

    if (!isEqual(memorized.current[index].dataUrl, dataUrlResolved)) {
      memorized.current = immutableSet(memorized.current, [index, 'dataUrl'], dataUrlResolved);
    }
    depIds = depIds.concat(...dataUrlDepIds);

    // conditional match multi data filters
    const [filterModelResolved, filterModelDepIds] = resolveDataFilters({
      dataFilters,
      store,
      currentData,
      params,
    });
    if (!isEqual(memorized.current[index].filterModel, filterModelResolved)) {
      memorized.current = immutableSet(
        memorized.current,
        [index, 'filterModel'],
        filterModelResolved,
      );
    }
    depIds = depIds.concat(...filterModelDepIds);
  });

  useDependencies(depIds);

  return memorized.current;
}
