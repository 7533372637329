import { extractDataDependencyIds, removeUndefined, resolveVariablePattern } from '@icp/utils';
import { FILTER_TYPES_UNARY } from '../constant';
import { selectContext, selectValues } from '../store';
import { resolveConditionalListProperty } from './conditionalUtils';

function distinct(list) {
  return Array.from(new Set(list));
}

function removeInvalidDataFilters(dataFilters) {
  const isValidSimpleValue = (value) => {
    // 目前来说 '' undefined  null 都是属于没意义的 filter，都过滤掉。如果有需求再修改。
    return typeof value === 'number' || (typeof value === 'string' && value);
  };
  return dataFilters.filter((item) => {
    const { filterType, type, filter, filterTo, dateFrom, dateTo, values } = item;

    if (FILTER_TYPES_UNARY.includes(type)) {
      return true;
    }

    if (filterType === 'text') {
      return isValidSimpleValue(filter);
    }
    if (filterType === 'number') {
      if (type === 'inRange') {
        return isValidSimpleValue(filter) && isValidSimpleValue(filterTo);
      }
      return isValidSimpleValue(filter);
    }
    if (filterType === 'date') {
      if (type === 'inRange') {
        return isValidSimpleValue(dateFrom) && isValidSimpleValue(dateTo);
      }
      return isValidSimpleValue(dateFrom);
    }
    if (filterType === 'set') {
      // TODO, set 没有做完善，不知道 values 数组里支持些什么东西
      return Array.isArray(values);
    }

    return true;
  });
}

function resolveFilterValue({ dataFiltersMatched, store, context, formData, currentData, params }) {
  const [dataFiltersResolved, depIdsInValues] = [[], []];

  if (!Array.isArray(dataFiltersMatched)) {
    return [dataFiltersResolved, depIdsInValues];
  }

  const resolveString = (pattern) => {
    return resolveVariablePattern({
      pattern,
      currentData: currentData || formData,
      formData,
      context,
      params,
    });
  };
  const resolveArray = (pattern) => {
    if (Array.isArray(pattern)) {
      return pattern.map((item) => resolveString(item));
    }
    return resolveString(pattern);
  };

  dataFiltersMatched.forEach((item) => {
    if (Array.isArray(item.conditions) && item.conditions.length > 0) {
      const [nestedConditions, nestedDepIds] = resolveFilterValue({
        dataFiltersMatched: item.conditions,
        store,
        context,
        formData,
        currentData,
        params,
      });

      // 填充循环值
      depIdsInValues.push(...nestedDepIds);
      dataFiltersResolved.push({
        operator: item.operator,
        conditions: nestedConditions,
      });
      return;
    }

    const noNeedValue = FILTER_TYPES_UNARY.includes(item.type);

    if (!noNeedValue) {
      ['filter', 'value', 'filterTo', 'dateFrom', 'dateTo', 'values'].forEach((key) => {
        depIdsInValues.push(...extractDataDependencyIds(item[key], currentData));
      });
      // value 同时支持直接写 ':abc' 和 [':abc', ':dev']
      if (Array.isArray(item.values)) {
        item.values.forEach((v) => {
          depIdsInValues.push(...extractDataDependencyIds(v, currentData));
        });
      }
    }

    const filterItem = removeUndefined({
      // TODO，早期的 dataFilter 里用的是 id，但是 Table 和后端 api 识别的都是 colId，这次加上 item.colId ?? 的逻辑
      // 是为了 useGanttDataSource 里直接用 FilterPanel 设置出来的 filterModel 里的 colId 直接使用。考虑以后
      // 合适的话把 dataFilter 的 json 配置一起统一成 colId 得了。
      colId: item.colId ?? item.id,
      filterType: item.filterType || 'text',
      type: item.type || 'contains',
      filter: noNeedValue ? undefined : resolveString(item.filter ?? item.value), // item.value is deprecated
      filterTo: noNeedValue ? undefined : resolveString(item.filterTo),
      dateFrom: noNeedValue ? undefined : resolveString(item.dateFrom),
      dateTo: noNeedValue ? undefined : resolveString(item.dateTo),
      values: noNeedValue ? undefined : resolveArray(item.values),
    });
    dataFiltersResolved.push(filterItem);
  });

  return [removeInvalidDataFilters(dataFiltersResolved), distinct(depIdsInValues)];
}

export default function resolveDataFilters({ dataFilters, store, currentData, params }) {
  const formData = selectValues(store.getState());
  const context = selectContext(store.getState());

  // check deprecated dataFilters
  if (process.env.NODE_ENV === 'development') {
    if (Array.isArray(dataFilters)) {
      for (const item of dataFilters) {
        if (item.id && item.type && item.value && item.matched) {
          console.error('dataFilter matched 的用法错误，将不会生效！');
        }
      }
    }
  }

  const [dataFiltersMatched, depIdsInMatched] = resolveConditionalListProperty({
    value: dataFilters,
    currentData,
    store,
    params,
  });

  const [dataFiltersResolved, depIdsInValues] = resolveFilterValue({
    dataFiltersMatched,
    store,
    formData,
    currentData,
    context,
    params,
  });

  return [dataFiltersResolved, distinct([...depIdsInMatched, ...depIdsInValues])];
}
