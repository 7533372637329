import { createContext, forwardRef, useEffect, useMemo, useRef } from 'react';
import { Provider, createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';
import PropTypes from 'prop-types';
import makeStore from './makeStore';
import { API_URLS_DEFAULT } from '../consts';

const ctx = createContext();

export const useStore = createStoreHook(ctx);
export const useDispatch = createDispatchHook(ctx);
export const useSelector = createSelectorHook(ctx);

function StoreProvider({ children, apiUrls }) {
  const [store, clearListeners] = useMemo(() => makeStore({ apiUrls }), [apiUrls]);

  useEffect(() => {
    return () => {
      clearListeners();
    };
  }, [clearListeners]);

  return (
    <Provider store={store} context={ctx}>
      {children}
    </Provider>
  );
}

StoreProvider.propTypes = {
  children: PropTypes.node,
  apiUrls: PropTypes.shape({}),
};

export function withStoreProvider(Component) {
  return forwardRef((props, ref) => {
    const apiUrlsRef = useRef();
    // eslint-disable-next-line react/prop-types
    apiUrlsRef.current = props.chatConfiguration?.apiUrls;

    const apiUrls = useMemo(() => {
      return new Proxy(
        {},
        {
          get: (target, key) => {
            return apiUrlsRef.current?.[key] ?? API_URLS_DEFAULT[key];
          },
        },
      );
    }, []);

    return (
      <StoreProvider apiUrls={apiUrls}>
        <Component ref={ref} {...props} />
      </StoreProvider>
    );
  });
}
