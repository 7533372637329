import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import authReducer from './features/authSlice';
import listeners from './features/listeners';

const reducer = {
  auth: authReducer,
};

const listenerMiddleware = createListenerMiddleware();

listeners.forEach((listener) => listenerMiddleware.startListening(listener));

export default configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production' || window.ICP_REDUX_DEBUG,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});
