import { get } from 'lodash-es';
import { getFilterModel } from '@icp/components';
import { AutoCellRenderer } from './cellRenders';

export const DEFAULT_PAGE_SIZE = 30; // 30 rows
export const DEFAULT_INTERVAL_TIME = 10; // 10 second
export const DEFAULT_COL_DEF = {
  flex: 1,
  minWidth: 100,
  resizable: true,
  sortable: true,
  filter: 'agTextColumnFilter',
  enableRowGroup: false,
  cellRenderer: AutoCellRenderer,
  filterParams: {
    maxNumConditions: 1,
  },
  // 禁止掉 ag-grid 的 cellDataType，否则当列有脏数据的时候（比如老数据是 string，后期把列改为调用 NumberCellEditor），编辑会无法修改数据。
  cellDataType: false,
  // filterValueGetter 给 Toolbar filterModel 精确搜索使用，在 clientSide 生效。需要同时兼容
  // 1. filterType 是 text 的普通输入框搜索，这种情况类似模糊搜索框，用所见即所得的字符串匹配；
  // 1. 用户配置了类型的精确 filter，例如 Number Date Set，这种需要保留数据的原始值，如 Number 和 Date 比较大小，
  // Set 使用 value 而不是用 label 匹配。
  filterValueGetter: (params) => {
    const { colDef, getValue } = params;
    const filterType = getFilterModel(colDef);
    if (filterType === 'text') {
      // 如果用户没有配置改列的 Toolbar filter
      return DEFAULT_COL_DEF.getQuickFilterText(params);
    }
    return getValue(colDef.field);
  },
  // getQuickFilterText 给 Toolbar 模糊搜索使用，在 clientSide 生效
  getQuickFilterText: (params) => {
    const { data, colDef, context } = params;

    // params.value 已经是 filterValueGetter 返回过后改变过后的值，不能直接使用。
    const value = get(data, colDef.field);
    if (value === undefined || value === null || !colDef.cellRenderer) {
      return value;
    }

    if (typeof colDef.cellRenderer.getQuickFilterText === 'function') {
      return colDef.cellRenderer.getQuickFilterText(params);
    }

    if (typeof colDef.cellRenderer.toDisplayText === 'function') {
      return colDef.cellRenderer.toDisplayText(value, colDef.cellRendererParams, context);
    }
    return value;
  },
};

// column types
export const ACL_COLUMN = 'ACL_COLUMN';
export const ACTION_COLUMN = 'ACTION_COLUMN';
export const AUTO_COLUMN = 'AUTO_COLUMN';
export const AUTO_INDEX_COLUMN = 'AUTO_INDEX_COLUMN';
export const CHECKBOX_COLUMN = 'CHECKBOX_COLUMN';
export const DATE_COLUMN = 'DATE_COLUMN';
export const ENUM_COLUMN = 'ENUM_COLUMN';
export const MULTI_VALUE_COLUMN = 'MULTI_VALUE_COLUMN'; /* @deprecated */
export const NUMBER_COLUMN = 'NUMBER_COLUMN';
export const PROGRESS_COLUMN = 'PROGRESS_COLUMN';
export const RADIO_COLUMN = 'RADIO_COLUMN';
export const SELECT_COLUMN = 'SELECT_COLUMN';
export const STATUS_COLUMN = 'STATUS_COLUMN';
export const TEXTAREA_COLUMN = 'TEXTAREA_COLUMN';
export const TEXT_COLUMN = 'TEXT_COLUMN';
export const TIME_COLUMN = 'TIME_COLUMN';
export const UPLOAD_COLUMN = 'UPLOAD_COLUMN';

// column actions
export const ACTION_COLUMN_VIEW = 'VIEW';
export const ACTION_COLUMN_EDIT = 'EDIT';
export const ACTION_COLUMN_DELETE = 'DELETE';
export const ACTION_COLUMN_TREE_DELETE = 'TREE_DELETE'; // 删除当前节点的同时级联删除所有子孙节点
export const ACTION_COLUMN_APPROVAL = 'APPROVAL';
export const ACTION_COLUMN_CALL_GLOBAL_METHOD = 'CALL_GLOBAL_METHOD';
export const ACTION_COLUMN_ACTIONS = [
  ACTION_COLUMN_VIEW,
  ACTION_COLUMN_EDIT,
  ACTION_COLUMN_DELETE,
  ACTION_COLUMN_TREE_DELETE,
  ACTION_COLUMN_APPROVAL,
  ACTION_COLUMN_CALL_GLOBAL_METHOD,
];
